// Capitalise first letter from each word in a string
String.prototype.capitalise = function(){
	return this.toLowerCase().replace( /\b\w/g, function (m) {
		return m.toUpperCase();
	});
};

// Define breakpoint widths
var breakpoints = {
	"mobileSmall": 479,
	"mobile": 767,
	"tablet": 991,
	"desktop": 1199
};
function breakpoint(device, less_than) {
	var lt = typeof less_than !== "undefined" ? less_than : true;
	return lt ? (window.innerWidth <= breakpoints[device]) : (window.innerWidth > breakpoints[device]);
}

// Accepts column 0-indexed numbers as an array
function getColsWidth($table_el, cols_arr) {
	if (typeof cols_arr === 'array' || typeof cols_arr === 'object') {
		var total_cols_width = 0;
		$.each(cols_arr, function(key, value){
			var this_col_width = $table_el.find('thead td[data-col="'+value+'"]').innerWidth() + 3;
			total_cols_width += this_col_width;
		});
		return parseInt(total_cols_width);
	}
}

// Util function to generate HTML for a new table row
function newTableRow(row_data, row_id) {
	if ( typeof row_data !== "undefined" && typeof row_data === "object" ) {
		var table_row = "";

		table_row += '<tr data-row="' + row_id + '">';

		var i = 0;
		$.each(row_data, function(key, value){
			var cell_class = "";
			if ( value.indexOf('Severity') > -1 ) {
				cell_class += value.toLowerCase().replace(' ', '-');
			}
			table_row += '<td data-col="' + i + '">';
			table_row += 	'<div class="cell ' + cell_class + '">';
			table_row += 		value;
			table_row += 	'</div>';
			table_row += '</td>';
			i++;
		});

		table_row += '</tr>';

		return table_row;
	}
	return false;
}

// Util function to test a string to see if it's a possible valid URL
function validURL(url) {
	var strRegex = "^s?https?:\/\/[-_.!~*'()a-zA-Z0-9;\/?:\@&=+\$,%#]+$";
	var re=new RegExp(strRegex);
	return re.test(url);
}

// General function to move back in the browser's history
function goBack() {
	window.history.back();
}

// Plugin to redraw a table
(function($){
	var rowHTML = function(row_data, row_num) {
		var table_row_wrapper = "",
			table_row = "",
			record_id = "",
			severity_checks = ['severity ', 'severity_', 'severity-', 'sev ', 'sev_', 'sev-'];

		table_row_wrapper += '<tr data-row="' + row_num + '"';

		if ( row_data[0].field == "id" ) {
			record_id = row_data[0].value;
			row_data.shift();
		}

		for ( var j = 0; j < row_data.length; j++ ) {
			var field_name = row_data[j].field;
			var field_value = row_data[j].value;

			var cell_class = "";

			// Check if severity field
			var field_value_lower = field_value.toLowerCase();

			// Do regex check
			for ( var k = 0; k < severity_checks.length; k++ ) {
				if ( field_value_lower.indexOf( severity_checks[k] ) > -1 ) {
					cell_class += field_value_lower.replace('_', '-').replace(' ', '-');
					break;
				}
			}

			table_row += '<td data-col="' + j + '">';
			table_row += 	'<div class="cell ' + cell_class + '">';
			table_row += 		field_value;
			table_row += 	'</div>';
			table_row += '</td>';
		}

		if (record_id !== "") table_row_wrapper += ' data-id="' + record_id + '"';
		table_row_wrapper += '>' + table_row + '</tr>';

		return table_row_wrapper;
	};

	function redraw($el, dat) {
		// Back up current table tbody before making changes
		var history = $el.data('history') || [];
		var $tbody = $el.find('tbody');
		var $current_tbody_contents = $tbody.contents();
		var new_history_obj = {
			tbody_inner: $current_tbody_contents
		}
		history.push(new_history_obj);
		$el.data('history', history);

		var rows_html = "";
		for ( var i = 0; i < dat.length; i++ ) {
			var row_arr = dat[i];
			rows_html += rowHTML( row_arr, i );
		}

		$tbody.html(rows_html);
	}

	$.fn.tableRedraw = function(data, options) {
		var $el = $(this);
		var dat = data || {};
		return redraw($el, dat);
	};
})(jQuery);

// Plugin for custom form validation (for filters)
(function($){
	var defaults = {
		field_wrapper: '.filter',	// specify a wrapper el class for each form element to look for
		required: '.required',		/* 	selector to specify if a field is required - looks for this on
										the field_wrapper if that was specified, otherwise will look
										for on the form element itself */
		tags: [						// specify tags or classes to check for (in this order)
			'textarea',
			'input',
			'select',
		],
		error_class: 'not-valid',	// name of the class to add to the filter field wrapper to indicate an error
		radio_groups: [],			// specify selectors for groups of radio inputs to validate in groups
	};

	// Wrapper function to run doValidate() and affect the form's HTML to display errors
	function validateForm($el) {
		var options = $el.data('options');

		var $empty_filters = getEmpty($el);

		if ($empty_filters === false) {
			return true;
		}
		else {
			$($empty_filters, $el).first().parents(options.field_wrapper).velocity('scroll', {duration:300, offset:-100});
			$($empty_filters, $el).each(function() {
				$(this).parents(options.field_wrapper).addClass(options.error_class);
			});
			return false;
		}
	}

	function getEmpty($el) {
		var options = $el.data('options');

		var $required_filter_els;

		if ( options.field_wrapper ) {
			if ( options.required ) {
				$required_filter_els = $(options.field_wrapper+options.required);
			}
		}

		var $empty_filter_els = false;

		$required_filter_els.each(function() {
			var $this_filter = $(this);

			// Check for each type of filter for validation
			for ( var i = 0; i < options.tags.length; i++ ) {
				var tag = options.tags[i];
				if ( $('> '+tag, $this_filter).length > 0 ) { // Check for field existence
					$('> '+tag, $this_filter).each(function() {
						var $this_filter_el = $(this);
						if ( $this_filter_el.prop('multiple') ) { // Multiselect

							var blank = "";

							var filtered = $this_filter_el.val().filter(function(element){
								return element !== blank;
							});

							if ( filtered.length === 0 ) {
								$empty_filter_els = ( !$empty_filter_els ) ? $this_filter_el : $empty_filter_els.add($this_filter_el);
							}

						}
						else {
							if ( $this_filter_el.attr('type') === "radio" && $this_filter_el.prop('checked') === false ) {

							}
							else if ( $this_filter_el.val().trim() == "" ) {
								$empty_filter_els = ( !$empty_filter_els ) ? $this_filter_el : $empty_filter_els.add($this_filter_el);
							}
						}
					});
				}
			}

			// Check for radio input groups
			for ( var j = 0; j < options.radio_groups.length; j++ ) {
				var $this_group = $(options.radio_groups[j]);
				var is_checked = false;
				$this_group.each(function() {
					if ( $(this).prop('checked') ) {
						is_checked = true;
					}
				});
				if ( !is_checked ) {
					$empty_filter_els = ( !$empty_filter_els ) ? $this_group.first() : $empty_filter_els.add($this_group.first());
				}
			}
		});

		if ( $empty_filter_els !== false && typeof $empty_filter_els.length !== "undefined" && $empty_filter_els.length === 0 ) {
			$empty_filter_els = false;
		}

		return $empty_filter_els;
	}

	function init($el, options) {
		var options = $.extend({}, defaults, options);

		$el.data('options', options);

		$el.submit(function(e){
			var validated = validateForm($el);
			$(options.error_class, $el).removeClass(options.error_class);
			if ( !validated ) {
				return false;
			}
		});

		$el.on('change keyup', '.'+options.error_class, function(e){
			$(this).removeClass(options.error_class);
		});
	}

	$.fn.validateForm = function(options) {
		var $el = $(this);
		if ( typeof options === "string" ) {
			switch (options) {
				case "validate":
					return validateForm($el);
					break;
				case "getEmpty":
					return getEmpty($el);
					break;
			}
			return;
		}
		else {
			opts = options || {};
			return init($el, opts);
		}
	}
})(jQuery);


// Plugin to initialise a given multiselect
(function(){
	var defaults = {
		multiselect_count: 0,
		parent_el: '.filter',
		inner_label: false,
	};

	function init($el, opts) {
		var opts = $.extend({}, defaults, opts);

		$el.data('options', opts);

		var $parent_filter = $el.parents(opts.parent_el);

		var $select = $el;
		if ( $select.css('display') != "none" ) { // Only process visible multiselects
			var $opts = $select.children('option');
			var options = [];
			var select_id = $select.attr('id') || "multi";
			select_id += '-'+opts.multiselect_count;

			$select.attr('data-multi-boundto', select_id);

			$opts.each(function(){
				var $opt = $(this);
				options.push({
					'label': $opt.text(),
					'name': (select_id+'-'+$opt.val()).replace(' ', '-').toLowerCase(),
					'value': $opt.val(),
					'selected': $opt.prop('selected'),
				});
			});

			var atts = {
				'data-role': $select.attr('data-role'),
				'data-table': $select.attr('data-table'),
			};

			var replacement_html = '';
			replacement_html += '<div data-multi-boundto="'+select_id+'" class="filter-multiselect clearfix" ';
			
			$.each(atts, function(key, val){
				if ( typeof val !== "undefined" ) {
					replacement_html += ' ' + key + '="' + val + '" ';
				}
			});

			replacement_html += '>';

			if ( options[0].value == "" ) {
				var inner_label = options.shift();
				inner_label = inner_label.label;
				replacement_html += '<span class="filter-multiselect-inner-label">' + inner_label + '</span>';
			}

			replacement_html += 	'<a href="#" class="filter-multiselect-action"></a>';
			replacement_html += 	'<input type="hidden" class="filter-multiselect-values">';
			replacement_html += 	'<div class="filter-multiselect-selection">';

			for ( var l = 0; l < options.length; l++ ) {
				var obj = options[l];
				replacement_html += 		'<div class="filter-multiselect-row">';
				replacement_html += 			'<label for="' + obj['name'] + '">' + obj['label'] + '</label>';
				replacement_html += 			'<input type="checkbox" name="' + obj['name'] + '" value="' + obj['value'] + '" class="filter-multiselect-checkbox"';
				if (obj['selected']) replacement_html += ' checked ';
				replacement_html += 			'>';
				replacement_html += 		'</div>';
			};

			replacement_html += 	'</div>';
			replacement_html += '</div>';

			$select.hide();
			$select.after(replacement_html);

			opts.multiselect_count++;
		}

		// Click handler for multiselect filter elements
		$parent_filter.find('.filter-multiselect-action').click(function(e){
			e.stopPropagation();
			e.preventDefault();
			var $filter_el = $(this).parents(opts.parent_el);
			$(opts.parent_el).not($filter_el).removeClass('filter-open');
			$filter_el.toggleClass('filter-open');
		});

		// Handle checkbox changes for multiselects
		$parent_filter.find('.filter-multiselect-checkbox').click(function(e){
			e.stopPropagation();
		});

		// Click handler for checkboxes inside filter multiselect elements
		$parent_filter.find('.filter-multiselect-checkbox').on('change', function(){
			var $this_checkbox = $(this);
			var $multiselect = $this_checkbox.parents('.filter-multiselect');
			var checked_boxes = getCheckedBoxes($multiselect, $multiselect.find('.filter-multiselect-values'));

			if ( typeof $multiselect.attr('data-role') !== "undefined" ) {
				if ( $multiselect.attr('data-role') == 'toggle-columns' ) {
					var $table_el = $($multiselect.attr('data-table'));
					var cols_arr = checked_boxes.split('|');

					showhideTableColumns($table_el, cols_arr);
				}
			}
		});
	}

	$.fn.multiSelect = function(options) {
		var $el = $(this);
		if ( typeof options === "string" ) {

		}
		else {
			var opts = options || {};
			return init($el, opts);
		}
	};
})(jQuery);

