// Vertical gauge control
// Accepts percentage values as a floating point number 0-100 (no % needed)
// If 'data-level' attribute exists on the gauge element and you wish to use this value, pass false when calling this plugin function
$.fn.gaugeLevel = function (level) {
	var $gauge = $(this);
	var $gauge_el;
	if ( $gauge.hasClass('.progress-gauge') ) {
		$gauge_el = $gauge;
	}
	else {
		$gauge_el = $gauge.find('.progress-gauge');
	}
	var $gauge_fill = $gauge.find('.progress-gauge-fill');

	var new_level = false;
	if ( typeof level !== "undefined" || level === false ) { // Check if an initial level is set on the HTML element if no level parameter was passed
		var init_level = $gauge_el.attr('data-level');

		if ( typeof init_level !== "undefined" ) {
			new_level = init_level;
		}
	}
	else if ( $.isNumeric(level) ) { // Otherwise, check if a value was passed as a parameter
		new_level = level;
	}

	if ( new_level !== false ) { // Only adjust gauge if a value was given
		new_level = parseFloat(new_level.replace('%', '')); // Ensure no trailing percentage & ensure floating number

		$gauge_fill.css({
			height: new_level+'%'
		});

		var $gauge_level_text = $gauge.find('.progress-gauge-current');

		if ( new_level == 0 ) {
			$gauge_el.removeClass('threat-low threat-mid threat-hi');
			$gauge_level_text.text('');
			$gauge_fill.css('opacity', 0);
		}
		else {
			$gauge_fill.css('opacity', 1);
			if ( new_level <= 33.33333 ) {
				$gauge_el.removeClass('threat-mid threat-hi').addClass('threat-low');
				$gauge_level_text.text('LOW');
			}
			else if ( new_level <= 66.66666 ) {
				$gauge_el.removeClass('threat-low threat-hi').addClass('threat-mid');
				$gauge_level_text.text('MID');
			}
			else {
				$gauge_el.removeClass('threat-low threat-mid').addClass('threat-hi');
				$gauge_level_text.text('HI');
			}
		}
	}
};

// Function to handle multiselect changes
// Optional second parameter specifies an input to store the checked boxes into the value of
function getCheckedBoxes($multiselect, $hidden_input) {
	if ( typeof $multiselect !== "undefined" && $multiselect.length > 0 ) {
		var $checkboxes = $multiselect.find('.filter-multiselect-selection input[type="checkbox"]');
		var new_checkbox_values = "";
		$checkboxes.each(function(){
			if ( $(this).prop('checked') ) {
				new_checkbox_values += $(this).val() + "|";
			}
		});
		new_checkbox_values = new_checkbox_values.substring(0, new_checkbox_values.length - 1);

		// Set the bound underlying select element to the new value
		var select_boundto = $multiselect.attr('data-multi-boundto');
		if ( typeof select_boundto !== "undefined" ) {
			var $select_el_boundto = $('select[data-multi-boundto="'+select_boundto+'"]');
			if ( $select_el_boundto.length > 0 ) {
				$select_el_boundto.val( new_checkbox_values.split('|') ).trigger('change');
			}
		}

		if ( typeof $hidden_input !== "undefined" ) {
			$hidden_input.val(new_checkbox_values);
			return new_checkbox_values; // Remove last pipe character
		}
		else {
			return new_checkbox_values; // Remove last pipe character
		}
	}
}

// Function to handle show/hide of table columns
// Accepts an array of 0-indexed column numbers
function showhideTableColumns($table_el, cols_arr) {
	if ( typeof $table_el !== "undefined" ) {
		var table_last_col = $table_el.find('thead tr td').last().attr('data-col');

		if ( typeof cols_arr === "undefined" || typeof cols_arr !== "object" || cols_arr.length == 0 ) {
			// Show all columns
			$('[data-col]', $table_el).removeClass('hidden');
		}
		else {
			for ( var i = 0; i <= table_last_col; i++ ) {
				i = i.toString();
				var $col_els = $('[data-col="'+i+'"]', $table_el);
				if ( cols_arr.indexOf(i) > -1 ) { // Found match, display this column number
					$col_els.removeClass('hidden');
				}
				else { // Column not in array, therefore hide this column
					$col_els.addClass('hidden');
				}
			}
		}
	}
}

// Function to handle sidebar elements centering to the viewport
function adjustSidebar() {
	var $sidebar = $('#sidebar');
	var sidebar_height = $sidebar.height();
	var sidebar_links_height = $('#sidebar-links').height();
	var sidebar_scroll = 0;
	var new_top = 0;

	// Calculate how far the sidebar links should be spaced from the top of the sidebar in order to center it within the window's height
	if ( $('body#fancy').length > 0 || (breakpoint("tablet", false) && sidebar_height > sidebar_links_height) ) {
		var sidebar_half = sidebar_height / 2;
		var sidebar_links_half = sidebar_links_height / 2;
		var new_top = sidebar_half - sidebar_links_half;
		new_top = (new_top < 0) ? 0 : new_top;
	}
	else {
		sidebar_scroll = $sidebar.scrollTop();
	}

	var translate = "translate(0," + new_top + "px)";
	//var sidebar_translate = "translate(0," + (new_top - sidebar_scroll + 15) + "px)";

	var $logo = $('#sidebar-logo');
	if ( new_top < 80 ) {
		$logo.css('position', 'relative');
	}
	else {
		$logo.css('position', 'absolute');
	}

	$('#sidebar-links').css({
		transform: translate,
		webkitTransform: translate,
		msTransform: translate,
	});

	// $('#sidebar-slideout').css({
	// 	'transform': sidebar_translate,
	// 	'-webkit-transform': sidebar_translate,
	// 	'-ms-transform': sidebar_translate,
	// });
}

// Variable to store the table's original tbody HTML
var init_recent_tickets_table = false;

// Plugin for handling dashboard threat level module & recent tickets
$.fn.dashboardThreatLevel = function(type) {
	var $this_row = $(this);

	// Only handle the action if the row isn't already selected (if it's a site clicked, not country)
	if ( !$this_row.hasClass('threat-row-active') ) {
		var row_id = $this_row.attr('data-id');
		var $threat_panel = $this_row.parents('.threat-level-panel');

		var $table = $threat_panel.attr('data-table');
		$table = $($table);

		// Store the initial table's tbody element to be used later when clicking back
		if ( type == "country" ) {
			$threat_panel.addClass('loading-country');
			$this_row.siblings('');

			var location = $this_row.children('.threat-level-location').text();
			$threat_panel.find('.threat-level-in-country .in-country-h3').text(location);

			if ( init_recent_tickets_table === false ) {
				init_recent_tickets_table = '<tbody>';
				init_recent_tickets_table += $table.find('tbody').html();
				init_recent_tickets_table += '</tbody>';
			}
		}

		if ( type == "site" ) {
			$this_row.siblings('.threat-level-panel-row').removeClass('threat-row-active');
			$this_row.addClass('threat-row-active');
		}

		$table.find('tbody').remove();
		$table.siblings('.table-loader').show();

		// Fetch individual sites threat data for Threat Level and Recent Tickets table
		var threat_data = getThreatLevelTickets(row_id, $threat_panel, type);
	}
};

// Function to retrieve data for threat level 
function getThreatLevelTickets(id, $threat_panel, type) {
	var return_data = [];

	// Use dummy data if set
	if ( typeof use_dummy_threat_data !== "undefined" && use_dummy_threat_data === true ) {

		if ( type == "country" ) {
			return_data = dummy_dash_threat_level_country;
		}
		else {
			return_data = dummy_dash_threat_level_site;
		}

		var delay = 0;
		if ( typeof simulate_threat_latency !== "undefined" && simulate_threat_latency === true ) {
			delay = 800;
		}

		setTimeout(function(){
			displayThreatLevelData(return_data, $threat_panel, type);
		}, delay);

	}
	else { // Use Ajax to aquire real data via API

		ajaxGetThreatLevelInfo(id, $threat_panel, type);

	}
}

// Function to process and handle data that comes back from the threat level ajax call
function displayThreatLevelData(threat_data, $threat_panel, type) {

	var $table = $threat_panel.attr('data-table');
	$table = $($table);

	if ( type == "country" ) {
		// Loop through sites to display in Threat Level panel
		var threat_level_sites_html = "";
		$.each(threat_data.threat_level_sites, function(key, site_obj){
			threat_level_sites_html += 	'<a href="#" data-id="' + site_obj.site_id + '" class="threat-level-panel-row col-xs-6 col-sm-4 col-md-4 col-lg-3">';
			threat_level_sites_html += 		'<div class="threat-level-status threat-' + site_obj.severity + '">' + site_obj.severity + '</div>';
			threat_level_sites_html += 		'<div class="threat-level-location">' + site_obj.site_name + '</div>';
			threat_level_sites_html += 	'</a>';
		});
		$threat_panel.find('.threat-level-in-country .in-country-list').html(threat_level_sites_html);
		// Toggle visibility of panel data
		$threat_panel.removeClass('loading-country').addClass('in-country');
	}

	var threat_data_tickets;
	if ( type == "country" ) {
		threat_data_tickets = threat_data.recent_tickets;
	}
	else {
		threat_data_tickets = threat_data;
	}
	// Loop through recent tickets to display in table below threat level panel
	var tbody_html = "<tbody>";
	var i = 0;
	$.each(threat_data_tickets, function(key, value){
		tbody_html += newTableRow(value, i);
		i++;
	});
	tbody_html += "</tbody>";
	$table.append(tbody_html);
	$table.siblings('.table-loader').hide();

}

// Function to add severity classes to table rows for mobile view severity markers along left side of the tables
function addTableRowSeverities() {
	$('.table .cell.severity-1, table .cell.severity-2, table .cell.severity-3, table .cell.severity-4').each(function(){
		var $cell = $(this);
		var severity_row_class = "severity-row-";
		if ( $cell.hasClass('severity-1') ) {
			severity_row_class += "1";
		}
		else if ( $cell.hasClass('severity-2') ) {
			severity_row_class += "2";
		}
		else if ( $cell.hasClass('severity-3') ) {
			severity_row_class += "3";
		}
		else if ( $cell.hasClass('severity-4') ) {
			severity_row_class += "4";
		}
		$(this).parents('tr').addClass(severity_row_class);
	});
}

// Util function to check if the string date passed is a valid date
var date_formats = [
	moment.ISO_8601,
	"DD/MM/YYYY",
	"D/MM/YYYY",
	"DD/M/YYYY",
	"D/M/YYYY",
	"D/M/YY",
	"DD MMM, YYYY",
	"DD MMM, YY",
	"DD MMMM, YY",
	"DD MMMM, YY",
	"D MMM, YYYY",
	"D MMM, YY",
	"D MMMM, YY",
	"D MMMM, YY",
	"DD MMM YYYY",
	"DD MMM YY",
	"DD MMMM YY",
	"DD MMMM YY",
	"D MMM YYYY",
	"D MMM YY",
	"D MMMM YY",
	"D MMMM YY",
];
function isValidDate(date_str) {
	return moment(date_str, date_formats, true).isValid();
}

// Plugin to dynamically resize filters based on the number of characters in the label
$.fn.resizeFilter = function() {
	var $filter = $(this);
	var $label = $filter.children('label').first();
	var label_width = 120;
	if ( $label.length > 0 ) {
		label_width = $filter.children('label').text().length * 12;
	}
	else {
		var $filter_input = $filter.find('input:not([type="hidden"])').first();
		if ( $filter_input.length > 0 && typeof $filter_input.attr('placeholder') !== "undefined" && $filter_input.attr('placeholder').length > 0 ) {
			label_width = $filter_input.attr('placeholder').length * 12;
		}
	}

	label_width = label_width > 200 ? 200 : label_width; // Ensure doesn't get set too wide
	label_width = label_width < 20 ? 20 : label_width; // Ensure doesn't get set too narrow

	$filter.css({
		width: (label_width + 100) + 'px'
	});
}


// All code to run once the page is ready
$(document).ready(function(){

	// Polyfill for pointer-events CSS rule
	PointerEventsPolyfill.initialize({});

	// Initialise Bootstrap popover (used like a tooltip)
	$('[data-toggle="popover"]').popover({
		trigger: 'click',
		container: 'body',
		placement: 'top',
	});
	$('body').on('click', '[data-toggle="popover"]', function(e) {
		e.stopPropagation();
	});
	$('body').on('click', '.popover', function(e) {
		e.stopPropagation();
	});
	$('[data-toggle="popover"]').on('show.bs.popover', function() {
		$('[data-toggle="popover"]').not($(this)).popover('hide');
	});

	// Stop default action for href links with # as the location
	$('body').on('click', 'a[href="#"]', function(e){
		e.preventDefault();
	});

	// Global resets for clicking outside of an element with a specific state
	$('html').click(function(){
		$('.filter').find('.filter-multiselect').parents('.filter').removeClass('filter-open');
		$('#sidebar.is-open, #sidebar-slideout').removeClass('is-open');
		$('#sidebar-links .has-sub.is-open').removeClass('is-open').children('.sidebar-submenu').velocity('slideUp', {duration: 300});
		$('#sidebar-slideout').html('');
		$('[data-toggle="popover"]').popover('hide');
		$('.export-cont').removeClass('is-open');
		$('.export-options').velocity('slideUp', {duration: 200});
		$('.vulnerability-feed-delete-confirm-msg').velocity('slideUp', {duration: 200});
		$('#header-new-ticket-dropdown').velocity('slideUp', {duration: 200});
		$('#header-new-ticket-button').removeClass('is-open');
		if ( $('#fancy-map-dropdown.is-open').length > 0 ) {
			fancyDropToggle(false);
		}
	});

	// On pageload, add severity classes to table rows for mobile view severity markers along left side of the tables
	addTableRowSeverities();

	$('#header-new-ticket-toggle').click(function(e) {
		e.preventDefault();
		e.stopPropagation();
		var $parent = $(this).parent();
		$parent.toggleClass('is-open');
		if ( $parent.hasClass('is-open') ) {
			$('#header-new-ticket-dropdown').velocity('slideDown', {
				duration: 200
			});
		}
		else {
			$('#header-new-ticket-dropdown').velocity('slideUp', {
				duration: 200
			});
		}
	});

	// Add jQuery UI datepickers if applicable
	if ( $('input[type="date"]').length > 0 ) {

		$('input[type="date"]').attr({
			'type': 'text',
			'placeholder': 'Select a date'
		}).addClass('jqueryui-datepicker-input').datepicker({
			numberOfMonths: 1,
			showButtonPanel: true,
			dateFormat: 'd M, yy',
			beforeShow:function( input, inst ) {
				var dp = $(inst.dpDiv);
				var offset = $(input).outerWidth(false) - dp.outerWidth(false);
				dp.css('margin-left', offset);
			},
		});

		// Set a class on the datepicker
		if ( $('.table-filters-cont .jqueryui-datepicker-input').length > 0 ) {
			$('#ui-datepicker-div').addClass('table-filter-datepicker');
		}

		// Handling for valid dates in datepicker fields
		$('body').on('change', '.jqueryui-datepicker-input', function(){
			var $datepicker_input = $(this);
			if ( $datepicker_input.val() != '' && !isValidDate($datepicker_input.val()) ) { // If the date isn't valid, wipe the input's value
				$datepicker_input.val('');
				$datepicker_input.attr('placeholder', 'Invalid date');
			}
			else {
				$datepicker_input.attr('placeholder', 'Select a date');
				var date_obj = moment($datepicker_input.val(), date_formats);
				var m = date_obj.month()+1;
				m = m < 10 ? '0'+m : m;
				var d = date_obj.date();
				d = d < 10 ? '0'+d : d;
				$datepicker_input.attr('data-date', d+'/'+m+'/'+date_obj.year());
			}
		});

	}

	// Sidebar menu buttons click handler
	$('#header-menubutton, #sidebar-menubutton').click(function(e){
		e.stopPropagation();
		$('#sidebar').toggleClass('is-open');
	});

	// Prevent sidebar clicks from triggering HTML click event listener
	$('#sidebar, #sidebar-slideout, #sidebar-links .has-sub a').click(function(e){
		e.stopPropagation();
	});

	// Helper variables for tap events on sidebar links
	var touchscreen = false;
	var tapped = false;
	var tap_timer = null;

	// Sidebar menu submenu click handler
	$('#sidebar-links .has-sub').on('mouseup touchend', function(e){
		e.stopPropagation();

		// If tap event, set variables to prevent the click event (that follows this) from acting
		if ( e.type == 'touchend' ) {
			touchscreen = true;
			tapped = true;
			tap_timer = setTimeout(function(){
				tapped = false;
			}, 500);
		}

		// Only proceed with showing submenu if the event was a mouse click
		if ( e.type == 'mouseup' && tapped === false && touchscreen === false ) {
			clearTimeout(tap_timer);

			var $li_el = $(this);

			$('.sidebar-li-top.has-sub', '#sidebar').not($li_el).removeClass('is-open').children('.sidebar-submenu').velocity('slideUp', {
				duration: 300
			});
			$li_el.toggleClass('is-open');

			if ( $li_el.hasClass('is-open') ) {
				$li_el.children('.sidebar-submenu').velocity('slideDown', {
					duration: 300
				});
			}
			else {
				$li_el.children('.sidebar-submenu').velocity('slideUp', {
					duration: 300
				});
			}
		}
	});

	// Sidebar menu submenu click handler
	$('#sidebar').on('mouseenter mouseover', function(e){
		var $sidebar = $(this);
		$sidebar.addClass('is-open');
	});
	$('#sidebar').on('mouseleave', function(e){
		if ( breakpoint("tablet", false) ) {
			var $sidebar = $(this);
			$sidebar.removeClass('is-open');
			var $open_menu_items = $('.has-sub', $sidebar);
			$open_menu_items.removeClass('is-open').children('.sidebar-submenu').velocity('slideUp', {
				duration: 300
			});
		}
	});

	// Tap event handler for sidebar
	var sidebar_el = document.getElementById('sidebar');
	if ( sidebar_el !== null ) {
		sidebar_el.addEventListener("touchend", function(e){
			//$('[data-toggle="tooltip"]').tooltip('destroy');
			if ( breakpoint("tablet", false) ) {
				$('#sidebar').addClass('is-open');
			}
		}, false);

		// Tap event handlers for sidebar links
		// Override default toplinks click event on tap and when sidebar closed
		var sidebar_toplinks = sidebar_el.getElementsByClassName('sidebar-li-top');
		for ( var i = 0; i < sidebar_toplinks.length; i++ ) {
			var sidebar_toplink = sidebar_toplinks[i];
			sidebar_toplink.addEventListener("touchend", function(e){
				var $this_top_link = $(this);
				if ( !$('#sidebar').hasClass('is-open') ) { // Sidebar not open, prevent top-level links being active for touch for below if statement to work
					e.preventDefault();
				}
				else { // Sidebar is open, allow top-level links to function normally
					$('.sidebar-li-top.has-sub', '#sidebar').not($this_top_link).removeClass('is-open').children('.sidebar-submenu').velocity('slideUp', {
						duration: 300
					});
					$this_top_link.toggleClass('is-open');
					if ( $this_top_link.hasClass('is-open') ) {
						$this_top_link.children('.sidebar-submenu').velocity('slideDown', {
							duration: 300
						});
					}
					else {
						$this_top_link.children('.sidebar-submenu').velocity('slideUp', {
							duration: 300
						});
					}
				}

				// Above tablet breakpoint (i.e. tablet in landscape or desktop with touch), toggle sidebar's full visibility
				if ( breakpoint("tablet", false) ) {
					$('#sidebar').addClass('is-open');
				}
			}, false);

			var sidebar_toplink_submenu = sidebar_toplink.getElementsByClassName('sidebar-submenu')[0];
			if ( typeof sidebar_toplink_submenu !== "undefined" && sidebar_toplink_submenu !== null ) {
				var sidebar_toplink_children_links = sidebar_toplink_submenu.getElementsByTagName('A');
				for ( var j = 0; j < sidebar_toplink_children_links.length; j++ ) {
					sidebar_toplink_children_links[j].addEventListener("touchend", function(e){
						e.stopPropagation();
					}, false);
				}
			}
		}
	}

	// Automate dashboard gauge movement on pageload after a short delay
	setTimeout(function(){
		$('#dashboard-security-overall').gaugeLevel(false);
	}, 500);

	// Convert selects to Chosen plugin selects
	$('.filter-chosen').chosen({
		width: 'auto',
		placeholder_text_single: '&nbsp;'
	});
	// Handle chosen dropdowns and z-index toggling
	$('.filter-chosen').on('chosen:showing_dropdown', function() {
		var $this = $(this);
		var $parent = $this.parents('.filter-cont').length > 0 ? $this.parents('.filter-cont') : $this.parents('.filter');
		$parent = $this.parents('.filter');
		$parent.addClass('filter-open');
	});
	$('.filter-chosen').on('chosen:hiding_dropdown', function() {
		var $this = $(this);
		var $parent = $this.parents('.filter-cont').length > 0 ? $this.parents('.filter-cont') : $this.parents('.filter');
		$parent = $this.parents('.filter');
		$parent.removeClass('filter-open');
	});

	$('.filter.auto-size').each(function(){
		$(this).resizeFilter();
	});

	// Convert multiselects to custom HTML
	$('select[multiple]').each(function() {
		$(this).multiSelect();
	});

	// Export button click handler
	$('.button-export').click(function(e) {
		e.stopPropagation();
		var $export_options = $(this).siblings('.export-options');
		var $export_cont = $('.export-cont');
		if ( $export_cont.hasClass('is-open') ) {
			$export_cont.removeClass('is-open')
			$export_options.velocity('slideUp', {duration: 200});
		}
		else {
			$export_cont.addClass('is-open')
			$export_options.velocity('slideDown', {duration: 200});
		}
	});

	// Transfer clicks on labels to the nearest input
	$('body').on('click', '.filter > label', function(e){
		e.stopPropagation();
		var $sibling_input = $(this).siblings('input');
		if ( $sibling_input.length > 0 ) {
			$sibling_input.click();
		}
	});

	// Transfer clicks on labels to their designated checkbox
	$('body').on('click', '.filter .filter-multiselect-row label', function(e){
		e.stopPropagation();
		var sibling_checkbox_name = $(this).attr('for');
		var $sibling_checkbox = $('input[type="checkbox"][name="'+sibling_checkbox_name+'"]');
		$sibling_checkbox.prop('checked', !$sibling_checkbox.prop('checked')); // Toggle checkbox
		$sibling_checkbox.trigger('change');
	});

	// Check on pageload which boxes are checked in multiselects
	$('.filter-multiselect').each(function(){
		getCheckedBoxes($(this), $(this).parents('.filter').find('.filter-multiselect-values'));
	});

	// For tables with a row limit set, set the height of the container element
	$('table[data-row-limit]').each(function(){
		var $table = $(this);
		var limit = parseInt($table.attr('data-row-limit'));
		if ( limit > 0 && $table.find('tbody tr').length > limit ) {
			var head_height = $table.find('thead').height();
			var row_height = $table.find('tbody tr').first().height();
			var new_cont_height = (limit * row_height) + head_height;
			$table.parent().css('height', new_cont_height);
		}
	});

	$('.threat-level-all-countries').on('click', '.threat-level-panel-row', function(){
		var $this_row = $(this);
		var type = 'country';
		$this_row.dashboardThreatLevel(type);
	});

	$('.threat-level-in-country').on('click', '.threat-level-panel-row', function(){
		var $this_row = $(this);
		var type = 'site';
		$this_row.dashboardThreatLevel(type);
	});

	$('.threat-level-panel-back').click(function(){
		var $this_but = $(this);
		var $threat_panel = $this_but.parents('.threat-level-panel');
		$threat_panel.removeClass('in-country');
		var $table = $threat_panel.attr('data-table');
		$table = $($table);
		$table.children('tbody').remove();
		$table.append(init_recent_tickets_table);
	});

	// Align sidebar to center vertically on desktop/tablet and maintain when scrolling the sidebar
	adjustSidebar();
	$('#sidebar').scroll(function(){
		adjustSidebar();
	});

	// Fancy dashboard ticker
	var $ticker = $('#fancy-header-ticker');
	var $ticker_items = $ticker.find('.fancy-ticker-item');
	var ticker_count = $ticker_items.length;
	if ( ticker_count > 0 ) {

		// Clone ticker items to enable smoother scolling
		$ticker_items.clone().addClass('ticker-item-dupe').appendTo($ticker);

		$('#fancy-header-ticker').webTicker({
			speed: 30, //pixels per second
			direction: "left", //if to move left or right
			moving: true, //weather to start the ticker in a moving or static position
			startEmpty: false, //weather to start with an empty or pre-filled ticker
			duplicate: true, //if there is less items then visible on the ticker you can duplicate the items to make it continuous
			rssurl: false, //only set if you want to get data from rss
			rssfrequency: 0, //the frequency of updates in minutes. 0 means do not refresh
			updatetype: "reset" //how the update would occur options are "reset" or "swap"
		});

	}

	// Click handler for advanced filters button on tables
	$('.advanced-filters-button').click(function(){
		var $advanced_filters_button = $(this);
		var $advanced_filters_cont = $advanced_filters_button.siblings('.advanced-filters-cont');
		$advanced_filters_cont.toggleClass('is-open');
		if ( $advanced_filters_cont.hasClass('is-open') ) {
			$advanced_filters_cont.velocity('slideDown', {duration: 400});
			$advanced_filters_button.text('Hide Advanced Filters');
		}
		else {
			$advanced_filters_cont.velocity('slideUp', {duration: 400});
			$advanced_filters_button.text('Show Advanced Filters');
		}
	});

	// On login screen pageload, if any inputs have values already stored, set row to active
	$('.login-form-input').each(function(){
		var $input = $(this);
		if ( $input.val() != "" ) {
			$input.parents('.login-form-row').addClass('is-active');
		}
	});

	// Handlers for login form fancy labels hiding/revealing
	$('.login-form-input').on('click focus', function() {
		var $input = $(this);
		$input.parent().addClass('is-active');
	});
	$('.login-form-input').on('blur focusout', function() {
		var $input = $(this);
		if ( $input.val() == "" ) {
			$input.parent().removeClass('is-active');
		}
		else {
			$input.parent().addClass('is-active');
		}
	});

	$('#login-form-1').validateForm({
		field_wrapper: '.login-form-row',
		required: '.required',
		tags: ['input'],
		error_class: 'is-error',
	});
	$('#login-form-2').validateForm({
		field_wrapper: '.login-form-row',
		required: '.required',
		tags: ['input'],
		error_class: 'is-error',
	});

	// $('#login-form-1').on('change keyup', '.login-form-input', function() {
	// 	var $form_2 = $('#login-form-2');
	// 	if ( $form_2.css('display') !== "none" ) {
	// 		$form_2.velocity('slideUp', {duration: 300});
	// 		$form_2.find('.is-error').removeClass('is-error');

	// 		var $login_otp = $form_2.find('input[name="login-otp"]');
	// 		$login_otp.val('');
	// 		$login_otp.blur();
	// 		$('#login-form-submit').velocity('slideDown', {duration: 300});
	// 	}
	// });

	// Login form submit handling
	// $('.login-form input').on('keyup change', function() {
	// 	$(this).parent().removeClass('is-error');
	// });

	// 1st login form - Override login form submission
	// $('#login-form-1').on('submit', function(e) {
	// 	e.preventDefault();
	// 	var $form = $(this);
	// 	var $form_2 = $('#login-form-2');

	// 	// Signal state for form being submitted
	// 	$form.addClass('form-submitted');

	// 	// Find fields marked with an error, remove error class
	// 	$form.find('.is-error').removeClass('is-error');

	// 	var $username_input = $form.find('input[name="login-username"]');
	// 	var $password_input = $form.find('input[name="login-password"]');
	// 	var username = $username_input.val();
	// 	var password = $password_input.val();

	// 	// Variable to store boolean depending on if any inputs have an error
	// 	var input_error = false;

	// 	// Check if any inputs are empty (also trim to cover situations where only whitespace is entered)
	// 	if ( username.trim() == "" ) {
	// 		input_error = true;
	// 		$username_input.parents('.login-form-row').addClass('is-error');
	// 	}
	// 	if ( password.trim() == "" ) {
	// 		input_error = true;
	// 		$password_input.parents('.login-form-row').addClass('is-error');
	// 	}

	// 	// Proceed with form submission logic if the inputs have been filled in correctly
	// 	if ( !input_error ) {
	// 		if ( typeof fake_login !== "undefined" && fake_login === true ) { // Pretend to fire off OTP ajax call
	// 			setTimeout(function(){
	// 				if ( !force_login_error ) { // No forced form error
	// 					loginFormResult("login", true, dummy_login_response_success);
	// 				}
	// 				else { // Force error on form
	// 					loginFormResult("login", false, dummy_login_response_failure);
	// 				}
	// 			}, 800);
	// 		}
	// 		else { // Use real ajax call
	// 			ajaxPostLoginForm(username, password);
	// 		}
	// 	}
	// 	else { // Input errors, remove form submission state class
	// 		$form.removeClass('form-submitted');
	// 	}
	// });

	// 2nd login form - Override OTP form submission
	$('#login-form-2').on('submit', function(e) {
		if ( otp_response !== true ) { // Only override default form submit functionality if the otp_response isn't true
			e.preventDefault();
			var $form = $(this);
			var $form_1 = $('#login-form-1');

			// Signal state for form being submitted
			$form.addClass('form-submitted');

			// Find fields marked with an error, remove error class
			$form.find('.is-error').removeClass('is-error');

			var $otp_input = $form.find('input[name="login-otp"]');
			var otp = $otp_input.val();

			// Variable to store boolean depending on if any inputs have an error
			var input_error = false;

			// Check if any inputs are empty (also trim to cover situations where only whitespace is entered)
			if ( otp.trim() == "" ) {
				input_error = true;
				$otp_input.parents('.login-form-row').addClass('is-error');
			}

			// Proceed with form submission logic if the inputs have been filled in correctly
			if ( !input_error ) { // No error
				if ( fake_login ) { // Pretend to fire off OTP ajax call
					if ( typeof fake_login !== "undefined" && fake_login === true ) { // Pretend to fire off OTP ajax call
						setTimeout(function(){
							if ( !force_otp_error ) { // No forced form error
								loginFormResult("otp", true, dummy_otp_response_success);
							}
							else { // Force error on form
								loginFormResult("otp", false, dummy_otp_response_failure);
							}
						}, 800);
					}
				}
				else {
					ajaxPostOTPForm();
				}
			}
			else { // Error with one of the form inputs
				$form.removeClass('form-submitted');
			}
		}
	});

	// Regenerate OTP button click
	$('#login-otp-regenerate').click(function(){
		var $button = $(this);
		var $form_1 = $('#login-form-1');
		var $form_2 = $('#login-form-2');
		$button.children('i').show();
		$form_2.velocity('slideUp', {duration: 300});

		$form_1.find('.login-form-row-message').text('').hide();
		$('#login-form-submit').velocity('slideDown', {duration: 300});
		$form_1.submit();
	});

	// Click handlers for Vulnerability feed page
	$('.vulnerability-feed-delete').click(function(e){
		e.stopPropagation();
		var $confirm_msg = $(this).siblings('.vulnerability-feed-delete-confirm-msg');
		if ( $confirm_msg.is(':visible') ) {
			$confirm_msg.velocity('slideUp', {duration: 400});
		}
		else {
			$confirm_msg.velocity('slideDown', {duration: 400});
		}
	});
	$('.vulnerability-feed-delete-confirm').click(function(e){
		e.stopPropagation();
	});



	// ========== NEW TICKET PAGES ========== //

	// New ticket file upload (override default HTML file uploader)
	//$('.filter-attachment').dropzone();

	$('#new-ticket-init-form').validateForm({
		radio_groups: ['input[name="newt-changepriority"]','input[name="newt-incidentseverity"]']
	});


	// All functions to run on window resize
	$(window).resize(function(){

		// Close sidebar on mobile views if resolution changes
		$('#sidebar.is-open, #sidebar-slideout').removeClass('is-open');
		$('#sidebar-links .has-sub.is-open').removeClass('is-open').children('.sidebar-submenu').velocity('slideUp', {
			duration: 300
		});

		// Align sidebar to center vertically on desktop/tablet
		adjustSidebar();

	});
	
});